<template>
  <div class="about">
    <div class="about-one">
      <svg class="icon" aria-hidden="true" @click="goHome">
        <use xlink:href="#iconlogo"></use>
      </svg>
      <img class="about-one-ab" src="../static/images/about1.png" />
      <div class="about-one-me">
        <img class="about-one-mel" src="../static/images/logos.png" />
        <img class="about-one-mei" src="../static/images/me.jpg" />
      </div>
      <p class="about-one-p">
        溶于世，却恋那山石。<br />
        有太多美好的东西，却难于选择。<br />
        如果不能选择，那就自己创造一方境地。<br />
        依旧恋那山石。<br />
        为此汇记纯粹的自己。<br />
      </p>
    </div>
    <div class="about-me">
      <div class="about-me-c"></div>
    </div>
    <div class="about-center">
      <div class="dashang">
        <p class="ds-header">作者信息</p>
        <p class="ds-js">
          看在这个作者分享不易上🥺…
        </p>
        <div class="ds-img">
          <img src="../static/images/wx1.png" />
          <img src="../static/images/zfb1.png" />
        </div>
        <div class="ds-btn">
          <p class="ds-btn-no" @click="goHome">直接返回</p>
          <p class="ds-btn-yes" @click="dsok">支持作者 {{ dscs[0] }}</p>
        </div>
      </div>
      <div class="letter">
        <p class="let-header">私信/反馈</p>
        <p class="let-title">联系方式</p>
        <input type="text" v-model="name" class="let-mail inpt" placeholder="QQ/微信/邮箱" />
        <p class="let-title">私信内容</p>
        <textarea v-model="replys" class="let-main inpt"></textarea>
        <p class="send-let" @click="snedLet" :class="{ isok: isok }">发送</p>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  data: function() {
    return {
      dscs: [],
      name: '',
      replys: '',
    };
  },
  created: function() {
    this.getds();
  },
  computed: {
    isok() {
      if (this.replys && this.name) {
        return true;
      } else {
        return false;
      }
    },
  },
  methods: {
    //跳转about
    goHome() {
      this.$router.push('/');
    },
    //点击大赏
    dsok() {
      this.CommonJs.getVisit(this, 3);
      this.$message.success('感谢您的支持～');
      this.goHome();
    },
    //获取大赏次数
    getds() {
      this.CommonJs.countVisit(this, 3, this.dscs);
    },
    //发送私信
    snedLet() {
      let that = this;
      if (that.isok) {
        that.$axios
          .post(that.GLOBAL.baseUrl + '/reply', {
            replys: that.replys,
            name: that.name,
          })
          .then(function(res) {
            let data = res.data;
            if (data.status == 200) {
              that.replys = '';
              that.$message.success('私信已发送，感谢您的反馈');
            } else {
              that.$message.error('发送出现错误');
            }
          })
          .catch(function(error) {
            that.$message.error('发送出现错误');
          });
      }
    },
  },
};
</script>
<style scoped lang="less">
.about {
  padding-bottom: 40px;
}
.about-one {
  position: relative;
  text-align: center;
  background: #171e2f;
  padding: 100px;
  .icon {
    position: absolute;
    top: 24px;
    left: 30px;
    font-size: 48px;
    color: #fff;
    cursor: pointer;
    display: none;
  }
  .about-one-ab {
    display: inline-block;
    width: 786px;
  }
  .about-one-me {
    margin: 100px auto 20px;
    width: 480px;
    height: 480px;
    background: #014c8b;
    border-radius: 240px;
    overflow: hidden;
    position: relative;
    .about-one-mei {
      width: 100%;
    }
    .about-one-mel {
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      width: 50%;
    }
  }
  .about-one-p {
    // display: none;
    position: absolute;
    top: 692px;
    right: 0;
    left: 0;
    margin: auto;
    text-align: left;
    width: 480px;
    color: rgba(255, 255, 255, 0.5);
    line-height: 2;
  }
}
.about-me {
  height: 40px;
}
.about-center {
  // position: absolute;
  width: 920px;
  height: 480px;
  display: flex;
  margin: 0px auto;
}
.dashang {
  width: 600px;
  height: 480px;
  text-align: center;
  border-radius: @radius-bg;
  background: @bg-color-top;
  .ds-header {
    font-size: 24px;
    color: @font-color-top;
    padding-top: 24px;
    font-weight: 700;
  }
  .ds-js {
    color: @font-color-bottom;
    padding-top: 10px;
    padding-bottom: 20px;
  }
  .ds-img {
    display: flex;
    justify-content: center;
    img {
      width: 200px;
      margin: 15px;
    }
  }
  .ds-btn {
    padding-top: 10px;
    display: flex;
    justify-content: center;
    cursor: pointer;
    p {
      height: 48px;
      border-radius: 24px;
      padding: 0 24px;
      line-height: 48px;
      margin: 12px;
      font-weight: 700;
    }
    .ds-btn-no {
      border: 1px solid @font-color-bottom;
      color: @font-color-bottom;
    }
    .ds-btn-yes {
      color: #fff;
      background: @primary-color;
    }
  }
}
.letter {
  height: 440px;
  width: 320px;
  padding: 20px;
  margin-left: 12px;
  border-radius: @radius-bg;
  background: @bg-color-top;
  position: relative;
  .let-header {
    font-size: 24px;
    color: @font-color-top;
    padding-top: 4px;
    font-weight: 700;
  }
  .let-title {
    padding-top: 16px;
    padding-bottom: 8px;
    color: @font-color-top;
    font-size: 14px;
    font-weight: 700;
  }
  .inpt {
    padding: 12px;
    border-radius: @radius-sm;
    background: @bg-color-other;
    border: 0;
    outline: none;
    width: 296px;
    line-height: 24px;
  }
  .let-main {
    resize: none;
    height: 160px;
    line-height: 20px;
  }
  .send-let {
    height: 48px;
    width: 120px;
    border-radius: 24px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 20px;
    bottom: 26px;
    background: @font-color-bottom;
  }
  .isok {
    color: #fff;
    background: @primary-color;
    cursor: pointer;
  }
}
</style>
