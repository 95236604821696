<template>
  <div class="mabout">
    <div class="a-logo">
      <svg class="a-l-icon" aria-hidden="true">
        <use xlink:href="#iconlogo2"></use>
      </svg>
      <p class="a-l-slong">逸刻时光</p>
    </div>
    <div class="a-clicks">
      <div class="a-c-shang cc" @click="shangc(1)">赏</div>
      <div class="a-c-mail cc" @click="shangc(2)">
        <svg class="a-c-icon" aria-hidden="true">
          <use xlink:href="#iconyoujian"></use>
        </svg>
      </div>
    </div>
    <div class="m-a-back">
      <p class="back-p" @click="goHome">返回首页</p>
    </div>
    <reward v-if="isopen==1" @clooseDt="clooseDt()" @goto="clooseDt()"></reward>
    <mail v-if="isopen==2" @clooseDt="clooseDt()" @goto="clooseDt()"></mail>
  </div>
</template>
<script>
import reward from "../../components/mobile/reward.vue";
import mail from "../../components/mobile/mail.vue";
export default {
  data: function () {
    return {
        isopen: 0,
    };
  },
  created: function () {
    
  },
   components: {
    reward,
    mail,
  },
  computed: {},
  methods: {
    //跳转about
    goHome() {
      this.$router.push("/");
    },
    //关闭详情
    clooseDt() {
      this.isopen = 0;
    },
    //点击打赏/写信
    shangc(e){
        this.isopen = e;
    },
    //发送私信
    snedLet() {
      let that = this;
      if (that.isok) {
        that.$axios
          .post(that.GLOBAL.baseUrl + "/reply", {
            replys: that.replys,
            name: that.name,
          })
          .then(function (res) {
            let data = res.data;
            if (data.status == 200) {
              that.replys = "";
              that.$message.success("私信已发送，感谢您的反馈");
            } else {
              that.$message.error("发送出现错误");
            }
          })
          .catch(function (error) {
            that.$message.error("发送出现错误");
          });
      }
    },
  },
};
</script>
<style scoped lang="less">
.mabout {
    position: fixed;
  background: @bg-color-top;
  width: 100%;
  height: 100%;
  .a-logo {
    text-align: center;
    padding-top: 40%;
    .a-l-icon {
      width: 148px;
      height: 120px;
      fill: @primary-color;
    }
    .a-l-slong {
      font-size: 32px;
      font-weight: 700;
    }
  }
    .a-clicks{
        display: flex;
        justify-content: center;
        position: absolute;
        bottom: 100px;
        width: 100%;
        .cc{
            width: 48px;
            height: 48px;
            border-radius: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: 12px;
        }
        .a-c-shang{
            color: #FF7551;
            background: rgba(255,117,81,0.1);
            font-weight: 700;
        }
        .a-c-mail{
            background: rgba(59,115,240,0.1);
            font-weight: 700;
            .a-c-icon{
                width: 20px;
                fill:#3B73F0;
            }
        }
    }
  .m-a-back {
    position: fixed;
    bottom: 24px;
    width: 100%;
    text-align: center;
    .back-p {
      cursor: pointer;
      display: inline-block;
      width: 160px;
      height: 48px;
      border: 1px solid @bg-color-bottom;
      border-radius: 24px;
      text-align: center;
      line-height: 48px;
    }
  }
}
</style>
