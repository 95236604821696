<template>
  <div class="mail">
      <!-- 打赏页面 -->
    <div class="dashang">
      <svg class="icon" aria-hidden="true" @click="clooser()">
        <use xlink:href="#iconguanbi1"></use>
      </svg>
      <div class="letter">
        <p class="let-header">私信/反馈</p>
        <p class="let-title">联系方式</p>
        <input
          type="text"
          v-model="name"
          class="let-mail inpt"
          placeholder="QQ/微信/邮箱"
        />
        <p class="let-title">私信内容</p>
        <textarea v-model="replys" class="let-main inpt"></textarea>
        <p class="send-let" @click="snedLet" :class="{ isok: isok }">发送</p>
      </div>
    </div>   
  </div>
</template>
<script>
export default {
  data: function () {
      return {
        name: "",
        replys: "",
      };
    },
    computed: {
    isok() {
      if (this.replys && this.name) {
        return true;
      } else {
        return false;
      }
    },
  },
    methods: {
        //关闭窗口
        clooser(){
            console.log('a')
            this.$emit("clooseDt");
        },
      //发送私信
    snedLet() {
      let that = this;
      if (that.isok) {
        that.$axios
          .post(that.GLOBAL.baseUrl + "/reply", {
            replys: that.replys,
            name: that.name,
          })
          .then(function (res) {
            let data = res.data;
            if (data.status == 200) {
              that.replys = "";
              that.$message.success("私信已发送，感谢您的反馈");
            } else {
              that.$message.error("发送出现错误");
            }
          })
          .catch(function (error) {
            that.$message.error("发送出现错误");
          });
      }
    },
    },
  };
</script>
<style scoped lang="less">
.mail{
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 1001;

.dashang{
  width: 100%;
  height: 100%;
  position:absolute;
  top: 0;
  background: @bg-color-top;
  .icon{
      font-size: 28px;
      color: @font-color-bottom;
      position: absolute;
      right: 24px;
      top: 16px;
      cursor: pointer;
  }
  .ds-header{
    font-size: 24px;
    color:@font-color-top;
    padding-top: 72px;
    font-weight: 700;
  }
  .letter {
  width: 100%;
  margin-top: 60px;
  padding: 24px;
  .let-header {
    font-size: 24px;
    color: @font-color-top;
    padding-top: 4px;
    font-weight: 700;
  }
  .let-title {
    padding-top: 16px;
    padding-bottom: 8px;
    color: @font-color-top;
    font-size: 14px;
    font-weight: 700;
  }
  .inpt {
    padding: 12px;
    border-radius: @radius-sm;
    background: @bg-color-other;
    border: 0;
    outline: none;
    width: 100%;
    line-height: 24px;
  }
  .let-main {
    resize: none;
    height: 180px;
    line-height: 20px;
  }
  .send-let {
    height: 48px;
    width: 120px;
    border-radius: 24px;
    line-height: 48px;
    font-weight: 700;
    text-align: center;
    color: #fff;
    position: absolute;
    right: 20px;
    bottom: 26px;
    background: @font-color-bottom;
  }
  .isok {
    color: #fff;
    background: @primary-color;
    cursor: pointer;
  }
}
}
}

</style>
